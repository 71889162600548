import logo from './logo.svg';
import './App.css';
import './css/bootstrap.min.css';
import './css/style.css'
import './css/addons/datatables.min.css'
import icon from './img/icon.png';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Adaptive Corporation - Under Construction</title>
        <meta property="og:title" content="The Adaptive Corporation - Under Construction" />
        <meta property="og:url" content="https://adaptivecorporation.com" />
        <meta property="og:image" content={icon} />
        <link rel="canonical" href="https://adaptivecorporation.com" />
      </Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Roboto&amp;display=swap" rel="stylesheet" />
      <br />
      <br />
      <br />
      <div class="container mt-5">
        <div class="row">
          <div class="col-12">
            <center>
              <h3>The Adaptive Corporation</h3>
              <img src={icon} class="mr-3" width="250px" height="250px" />
            </center>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-md-3 text-center timer-box pt-4 pb-4 mr-2">
            <div class="row">
              <div class="col-12">
                <div class="timer-box-num" name="days">14</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="timer-box-text">Days</div>
              </div>
            </div>
          </div>
          <div className="mt-4 row text-center">
            <div className="col-md-12">
              <h4>We are overhauling our frontend, we will be back within 14 days or less.</h4>
            </div>
          </div>

        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default App;
